import { BasicUserData, Endpoints, FetchHeaders, UserData } from "../types";
import { getCookie, getReducedBrowserCookies } from "./cookieUtils";
import { SESSION_COOKIE_NAME } from "../constants";

export function getEndpointAndHeaders(
  endpoints: Endpoints,
  userData: UserData
): { endpoint: string; headers: FetchHeaders } {
  let endpoint, headers;
  if (!userData.isLoginSSO) {
    endpoint = endpoints.basic;
    headers = {
      ssn: (userData as BasicUserData).socialSecurity,
      dob: (userData as BasicUserData).dateOfBirth,
    };
  } else {
    const dolSessionCookie = getCookie(SESSION_COOKIE_NAME);
    endpoint = endpoints.sso;
    headers = {
      "sso-token": dolSessionCookie != null ? dolSessionCookie : "",
      "browser-cookies": getReducedBrowserCookies(),
    };
  }

  return { endpoint, headers };
}
