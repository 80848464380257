import { useState } from "react";
import Header from "./Header";
import NJFooter from "./NJFooter";
import { Page } from "./shared/types";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "@newjersey/feedback-widget/feedback-widget.min.js";
import ClaimStatus from "./ClaimStatus";
import IDmeLogin from "./idMeVerification/IdMeLogin";
import Appeals from "./appeals/Appeals";
import AppealDetails from "./appeals/AppealDetails";
import {
  VERIFY_ROUTE,
  FEATURE_FLAG_CREATE_APPEALS_ROUTE,
  APPEALS_ROUTE,
  APPEAL_DETAILS_ROUTE,
} from "./shared/constants";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "feedback-widget": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

function App() {
  const [page, setPage] = useState(Page.Login);

  return (
    <>
      <Header />
      <Routes>
        <Route
          path="*"
          element={<ClaimStatus page={page} setPage={setPage} />}
        />

        <>
          <Route path={VERIFY_ROUTE} element={<IDmeLogin />} />
        </>

        {FEATURE_FLAG_CREATE_APPEALS_ROUTE && (
          <>
            <Route path={APPEALS_ROUTE} element={<Appeals />} />
            <Route path={APPEAL_DETAILS_ROUTE} element={<AppealDetails />} />
          </>
        )}
      </Routes>
      <NJFooter page={page} />
    </>
  );
}

export default App;
