import { EAdjCaseDynamoDB } from "./types";

const eAdjStatusSeverity = {
    Open: 0,
    "Closed - Resolved": 1,
    "Closed - Unresolved": 1,
    "Open - No Response": 1,
    "Open - Submitted": 2,
  };
  
type eAdjStatus = keyof typeof eAdjStatusSeverity;
  
export const sortBySeverity = (a: EAdjCaseDynamoDB, b: EAdjCaseDynamoDB) => {
    const severityA = eAdjStatusSeverity[a.Status as eAdjStatus];
    const severityB = eAdjStatusSeverity[b.Status as eAdjStatus];
    return severityA > severityB
      ? 1
      : severityA === severityB
      ? a.CreatedDate > b.CreatedDate
        ? 1
        : -1
      : -1;
  }