import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { logEvent } from "../shared/analytics";
import {
  APPEALS_DISCLAIMER_LAWS_URL,
  APPEALS_DISCLAIMER_REGULATIONS_URL,
} from "../shared/constants";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export default function Disclaimer() {
  const { t } = useTranslation();

  const [isDisclaimerExpanded, setIsDisclaimerExpanded] = useState(false);

  return (
    <div
      className="banner padding-3 sticky z-top line-height-sans-3"
      role="banner"
    >
      <div className="maxw-desktop font-sans-2xs">
        <div className="grid-container">
          <div className="grid-row">
            <div className="grid-col">
              <Trans i18nKey="appeals.disclaimer.topline">
                <strong>placeholder_child_0</strong>
              </Trans>
            </div>

            <div className="grid-col-auto">
              <button
                type="button"
                className="usa-button--unstyled cursor-pointer padding-left-1"
                aria-expanded={isDisclaimerExpanded}
                aria-label={
                  (isDisclaimerExpanded ? t("collapse") : t("expand")) +
                  t("appeals.disclaimer.ariaLabelSummary")
                }
                aria-controls="disclaimer-infotext"
                onClick={() => setIsDisclaimerExpanded(!isDisclaimerExpanded)}
              >
                {t("appeals.disclaimer.toplineLink")}
                <svg
                  className="usa-icon"
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                >
                  {isDisclaimerExpanded ? (
                    <use xlinkHref={icons + "#expand_less"}></use>
                  ) : (
                    <use xlinkHref={icons + "#expand_more"}></use>
                  )}
                </svg>
              </button>
            </div>
          </div>
          <div className="grid-row">
            {isDisclaimerExpanded && (
              <div id="disclaimer-infotext" className="margin-top-2">
                <Trans i18nKey="appeals.disclaimer.infoText">
                  <strong />
                  <a
                    className="usa-link blue-override"
                    href={APPEALS_DISCLAIMER_LAWS_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => logEvent("Clicked appeals law link")}
                  >
                    placeholder_child_1
                  </a>
                  <a
                    className="usa-link blue-override"
                    href={APPEALS_DISCLAIMER_REGULATIONS_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => logEvent("Clicked appeals regulations link")}
                  >
                    placeholder_child_2
                  </a>
                </Trans>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
