export const en = {
  somethingWentWrong: "Something went wrong. Please try again later.",
  logout: "Logout",
  error: "Error",
  search: "search",
  select: "Select",
  dolLogo: "New Jersey Department of Labor and Workforce Development official logo",
  njLogo: "the State of New Jersey logo",
  expand: "Expand",
  collapse: "Collapse",
  header: {
    njFlagAlt: "NJ flag",
    officialLabel: "Official government website",
    officialSite: "Official Site of the State of New Jersey",
    governor: "Governor Phil Murphy",
    lieutenantGovernor: "Lt. Governor Tahesha Way",
    getUpdates: "Get Updates",
  },
  unemploymentInsurance: "Unemployment Insurance",
  claimStatus: "Claim Status",
  emailLoginButton: "Log in with email",
  ssnLoginButton: "Log in with Social Security Number",
  ssnLogin: {
    ssn: "Social Security Number (SSN)",
    dob: "Date of birth",
    pleaseEnterFields:
      "Please enter in the fields below to view the status of your claim.",
    matchError:
      "The Social Security Number (SSN) and/or Date of Birth you entered do not match a record in the database. Please try again.",
    forExample: "For example:",
    exampleDate: "April 28 1986",
    months: {
      jan: "January",
      feb: "February",
      mar: "March",
      apr: "April",
      may: "May",
      jun: "June",
      jul: "July",
      aug: "August",
      sep: "September",
      oct: "October",
      nov: "November",
      dec: "December",
    },
    month: "Month",
    day: "Day",
    year: "Year",
    enter: "Enter",
    clear: "Clear",
  },
  loading: {
    pleaseWait: "Please wait while we check if you're logged in…",
    ifThisTakesLonger:
      "If this takes longer than 10 seconds, please <1>click here</1>.",
  },
  dontHaveAccount: "Don't have an email account yet? <1>Create one here</1>",
  healthResources: {
    heading: "Health Resources",
    ifYouNeedHealthInsurance:
      "If you need health insurance, you or your family may be eligible for free or low-cost coverage from NJ FamilyCare. To learn more, visit <1>www.njfamilycare.org</1>",
    forMoreInfoGetCoveredNJ:
      " For more information on health insurance options, and to see if you qualify for financial help to lower the cost of a plan, visit <1>Get Covered New Jersey</1>.",
    moreHealthResources:
      "More health resources like Children's Mental Health, Addiction Treatment and Support, Mental Health & Emotional Support, etc. can be found <1>here</1>.",
  },
  noClaim: {
    heading: "We were unable to find a claim in the system",
    body: "Please make sure the email you used to log in matches the claim you submitted. If you recently submitted a claim, please check this page again tomorrow as our system takes up to 24 hours to update.",
    fileClaimPrompt: "Haven't filed a claim yet?",
    fileClaimLink: "Begin application",
  },
  status: {
    ssnAbbreviation: "SSN",
    dobAbbreviation: "DOB",
    filed: "Filed",
    pending: "Pending",
    pendingExplanation:
      "Claims take up to 7 days to process. Information is updated nightly.",
    claimType: {
      UI: "<0>Unemployment Insurance</0> (maximum 26 weeks)",
      PUA:
        "<0>Pandemic Unemployment Assistance</0>" +
        " (PUA - This federal program ended September 4, 2021. Any remaining amount associated with this claim is not payable.)",
      EUB:
        "<0>Extended Unemployment Benefits</0>" +
        " (13 or 20 weeks of benefits depending on federal permissions)",
      PEUC:
        "<0>Pandemic Extended Unemployment Compensation</0>" +
        " (PEUC benefits are available through September 4. 2021. Even if a balance remains on your claim, no weeks can be claimed after the payment for September 4, 2021 as the program ends with payment for that week)",
      WD: "<0>Workforce Development</0>",
      TRA: "<0>Trade Readjustment Allowance</0>",
      ABDT: "<0>Additional Benefits During Training</0>",
      DDU: "<0>Disability During Unemployment</0>",
    },
    headerGreeting: "Welcome back,",
    email: "Email",
    idVerification: {
      title: "Identity Verification",
      incomplete: "Incomplete",
      completedOn: "Completed on",
      complete: "Complete",
      ariaLabelSummary: " summary of Identity Verification",
      mayBeNeededAlert: {
        heading: "Identity Verification may be needed",
        body: "If you have not yet completed identity verification, please do so to allow your claim to move forward.",
        button: "Check Verification Status",
      },
      incompleteAlert: {
        heading: "Identity Verification May Be Required*",
        body: "We cannot process your claim until you verify your identity. Please make sure to sign up with the same email you used on your application to avoid delays{{optionalEmailString}}.",
        button: "Verify Identity",
      },
      errorAlert:
        "We were unable to retrieve information about your identity verification status. Please refresh the page to try again.",
      ifAlreadyCompleted:
        "*If you have already completed this, please allow up to 5 business days for processing.",
      ifAlreadyCertifying:
        "*If you are already certifying each week successfully or being paid for a claim, you do not need to re-verify with ID.me.",
      ifVerified:
        "*If you have verified your identity with a previous claim, please log in to your existing ID.me account to connect your identity verification to this current claim.",
      summary: {
        heading: "ID.me Verification",
        body: "ID.me is a tool used to verify your identity after you have submitted a claim. You should have received an email about how to verify after submitting.",
      },
    },
    title: "Your Claim Status",
    updated:
      "Status displayed was updated yesterday. Please allow 24 hours for system updates.",
    claimDate: "Date of Claim:",
    program: "Program:",
  },
  eAdjudication: {
    error:
      "We were unable to retrieve information about further steps for your claim. Please refresh the page to try again.",
    issue: "Issue: ",
    caseID: "Case ID: ",
    submitAlert: {
      heading: "Submit Information by {{date}}",
      body: "If you do not submit the information on time, you may not be eligible for benefits.",
      submitBy: "Submit by: ",
      give24hrs:
        "If you have already submitted, please give 24 hours for the system to update.",
      button: "Submit Information",
    },
    reopenAlert: {
      heading: "Information Request Expired", // need new trans
      body: "You received a request for more information about your claim which was due on {{date}}. If you would still like to submit this information, please call 732-761-2020.", // need new trans
    },
    receivedAlert: {
      heading: "Information Received",
      body: "The information you submitted by request was received. An agent is reviewing your updated claim.", // changed from       body: "Your response to the request for additional information about your claim was successfully received. An agent is reviewing your updated claim.",
    },
  },
  selfService: {
    heading: "Self Service Options",
    submit: "Submit",
    error: {
      incorrectInfo: "The claimant ID you entered doesn't match your SSN/DOB.",
      sessionTimeout:
        "For your security we logged you out. Please <1>log in</1> again with your email.",
    },
    manageClaim: {
      title: "Manage your claim",
      subtitle: "See more information about your current claim, change personal information, etc.",
      button: "Manage claim",
    },
    scheduleAppt: {
      title: "Schedule an appointment",
      subtitle: "Schedule an in person appointment for help on your claim.",
      button: "Schedule Appointment",
    },
    resetPIN: {
      title: "Reset your PIN",
      subtitle: "You will need your PIN to certify each week.",
      pleaseEnter: "Please enter the following to reset your PIN:",
      notice24hrs: "PIN resets can take up to 24 hrs",
      claimantID: "Claimant ID",
      claimantIDExplanation:
        "Your Claimant ID can be found on printed notices you've received from NJDOL",
      newPIN: "New PIN",
      digits: "{{number}} digits",
      emailExplanation: "We will send reset instructions here",
      success:
        "You successfully requested to reset your PIN. Please allow 24 hours for processing.",
    },
    request1099: {
      title: "Download your 2023 1099-G document",
      subtitle:
        "You will need this at the end of the year when filing your taxes.",
      viewBoxes:
        "<0>View Boxes 1 & 4</0>" +
        " (Unemployment Compensation & Federal Income Tax Withheld) or " +
        "<2>get an emailed copy</2>" +
        " of your full 2023 1099-G",
      getEmailedCopy: "Get an emailed copy of your full 1099-G",
      for2021or2020: "For 2022 or 2021 1099-G, <1>click here</1>.",
      box1: "<0>Box 1</0> - Unemployment Compensation",
      box4: "<0>Box 4</0> - Federal Income Tax Withheld",
      pleaseEnter: "Please enter in your email to receive your 2023 1099-G",
      success:
        "You successfully requested your 2023 1099-G. An email will be sent within 48 hours.",
    },
    requestWaiver: {
      title: "Request a waiver application", // used to be "Download a waiver application"
      subtitle:
        "This form is a formal request to be forgiven for an overpayment",
      formalRequest:
        "This form is a formal request to be forgiven for an overpayment.",
      pleaseConfirm:
        "Please confirm that you would like to apply for a PUA/PEUC waiver by submitting your email address below:",
      success:
        "You successfully requested a PUA/PEUC waiver application. Please check your email for next steps.",
      weWillSend: "We will send the application here",
    },
  },
  issueDescription: {
    "A/A - 01 02": "Availability/Ability for full-time work",
    "ASW - 03": "You reported you are not actively searching for work",
    "VQ - 04": "Voluntarily quitting or retiring from your job",
    "MC - 05 39 55":
      "Potential performance issue and/or misconduct connected with your work",
    "RSWC - 06": "Potential refusal of suitable work",
    "RSWEP - 06": "Employer reported you refused suitable work",
    "RR - 07 22":
      "Your request to claim benefits for additional uncertified weeks",
    "PAID AFTER LDW - 08":
      "Received wages or separation pay after last day of work",
    "SCHOOL EMP_S - 09":
      "You are a school employee who may be returning after a break", // Replaced "." in key with "_"
    "EMPL_ FT-C - 10": "Full-time employment while collecting benefits", // Replaced "." in key with "_"
    "EMPL_ FT-EM - 10":
      "Full-time employment reported by employer while you collected benefits", // Replaced "." in key with "_"
    "Pension - 15": "Received or applied for pension/retirement pay",
    "SELF EMP": "Possible self-employment",
    "CORP OFF": "Possible corporate officer of employer listed on claim",
    OTHER: "Reason for work separation is unclear",
    "FOREIGN IP": "Weekly certification was done outside of the U.S.",
    STUDENT2: "Student status may impact eligibility",
    CV19: "Work separation may be due to COVID-19",
    HOURS: "Potential refusal to work all hours offered by employer",
    "PUA-V": "Proof of income required for your PUA claim",
    "EMP W EARNINGS": "Failure to report earnings while collecting UI benefits",
    DNR: "Missed a scheduled appointment",
    SAVE: "Authorization to work in the United States of America",
    "TOLL OUT": "Previously disqualified for unemployment benefits",
  },
  footer: {
    returnToTop: "Return to top",
    officialSite: "An official website of the <1>the State of New Jersey</1>",
    njHome: "NJ Home",
    agencyIdentifier: "Agency identifier",
    agencyDescription: "Agency description",
    servicesAZ: "Services A to Z",
    departments: "Departments/Agencies",
    faqs: "FAQs",
    contactUs: "Contact Us",
    privacyNotice: "Privacy Notice",
    legalStatement: "Legal Statement & Disclaimers",
    accessibility: "Accessibility",
    opra: "Open Public Records Act (OPRA)",
    copyright: "Copyright",
  },
  appeals: {
    appeal: "Appeal",
    appealNumber: "Appeal {{numericalAppealNumber}}",
    appellants: {
      Claimant: "Claimant",
      Deputy: "Deputy",
      Employer: "Employer",
    },
    decision: "Decision",
    decisionDate: "Decision date",
    decisionsFound: "{{totalResultsCount}} cases found",
    detailsError: "There was an error finding that appeal. <1>Please search again</1>.",
    disclaimer: {
      topline: "<0>This resource is provided for informational purposes only</0> and is not a substitute for legal research or the advice of an attorney.",
      toplineLink: "Learn more here",
      infoText: "<0>Laws and rules can change over time.</0> Also, the outcome of a particular case is based on the specific facts and evidence presented during the appeal. For these reasons, the decisions you find in this database will not always apply to your individual case. It is important that you learn about the New Jersey unemployment <1>law</1> and <2>regulations</2> that are relevant to your specific issue(s).",
      ariaLabelSummary: " more information about this resource.",
    },
    dispositions: {
      D1: "Affirmed",
      D2: "Modified",
      D3: "Reversed",
      D4: "Remand to Deputy",
      D5: "Remand to Appeal Tribunal",
      D6: "Remand from Superior Court",
      D7: "Dismissed (Non-Appearance)",
      D8: "Dismissed without Prejudice",
      D9: "Dismissed (Late)",
      D10: "Dismissed (No Justifiable Issue)",
      D11: "Withdrawn",
      D12: "Dismissed (Denial of Reopening)",
      D13: "Dismissed (Other)",
    },
    docket: "Docket",
    docketNumber: "Docket number",
    error: "There was an error finding appeals decisions.",
    filedBy: "Filed by",
    issue: "Issue",
    issueCodes: {
      "001": "Voluntary Leaving",
      "002": "Discharge For Misconduct",
      "003": "Refusal Of Suitable Work",
    },
    jurisdiction: "Jurisdiction",
    jurisdictions: {
      AT: "Appeal Tribunal",
      BoR: "Board of Review",
    },
    lastPage: "Last page",
    next: "Next",
    nextPage: "next page",
    noDecisionText: "There is no decision text available for this case.",
    page: "page {{pageNumber}}",
    previous: "Previous",
    previousPage: "previous page",
    searchCases: "Search case(s)",
    searchingFor: `Searching for: "{{searchTerm}}"`,
    searchPlaceholder: "Search tips: A phrase, keyword or a specific docket #",
    searchTitle: "UI Appeals Case search",
    selectParty: "Select a person/party",
    selectTime: "Select a time range",
    selectTopic: "Select a specific topic",
    showCasesAbout: "Show me appeals cases about",
    timeframe: {
      oneYear: "1 year",
      sixMonths: "6 months",
      thirtyDays: "30 days",
    },
    title: "Welcome to the <1>Appeals Research Tool</1>",
    titlePrompt:
      "This is a database of past Unemployment Insurance appeal decisions. Search for similar cases to your own to learn and prepare for your appeal hearing.",
    whereDecisionWas: "where the final decision was in favor of the",
    withinTheLast: "within the last",
  },
};
