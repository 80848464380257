import { logEvent } from "./shared/analytics";

interface AccordionEntry {
  title: string;
  subtitle?: string;
  content: JSX.Element;
  id: string;
}

interface Props {
  items: AccordionEntry[];
  analyticsAction: string;
  extraClassNames?: string;
  extraButtonClassNames?: string;
}

function Accordion({
  items,
  analyticsAction,
  extraClassNames,
  extraButtonClassNames,
}: Props) {
  return (
    <div className={`usa-accordion ${extraClassNames}`}>
      {items.map((item, index) => (
        <div key={`accordion-key-${index}`}>
          <button
            type="button"
            className={`usa-accordion__button ${extraButtonClassNames}`}
            aria-expanded="false"
            aria-controls={item.id}
            onClick={() => logEvent(analyticsAction, `${item.id}`)}
          >
            <div className="accordion-button-content">
              <h3
                className={`usa-accordion__heading ${
                  item.subtitle != null ? "accordion-title-with-subtitle" : ""
                }`}
              >
                {item.title}
              </h3>
              {item.subtitle != null && (
                <p className="margin-top-1 font-sans-2xs text-normal display-none mobile-lg:display-block">
                  {item.subtitle}
                </p>
              )}
            </div>
          </button>
          <div id={item.id} className="usa-accordion__content usa-prose" hidden>
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordion;
