import { useTranslation } from "react-i18next";

function EAdjErrorAlert() {
  const { t } = useTranslation();
  return (
    <div className="usa-alert usa-alert--error usa-alert--slim">
      <div className="usa-alert__body">
        <p className="usa-alert__text">{t("eAdjudication.error")}</p>
      </div>
    </div>
  );
}

export default EAdjErrorAlert;
