// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

// must be defined in our GA console - we can add more if needed
export interface EventParameters {
  alert_type?: string;
  claimant_state?: string;
  event_category?: string;
  event_details?: string;
  event_label?: string;
  i18nkey?: string;
  object_details?: string;
  object_status?: string;
  object_type?: string;
  page_title?: string;
}

// Based on Universal Analytics schema of "Event Action", "Event Category", "Event Label"
export function logEvent(
  action: string,
  label: string | undefined = undefined
): void {
  if (typeof window.gtag === "function") {
    window.gtag("event", action, {
      event_category: "UI Claim Status",
      event_label: label,
    });
  } else {
    console.log(
      "In production, the following event would be logged to Google Analytics:",
      {
        action,
        label,
      }
    );
  }
}

// Used when you want to supply more custom event parameters beyond the UA schema
export function logGA4Event(eventName: string, eventParameters: EventParameters): void {
  if (typeof window.gtag === "function") {
    window.gtag("event", eventName, eventParameters);
  } else {
    console.log(
      "In production, the following event would be logged to Google Analytics:",
      {
        eventName,
        eventParameters,
      }
    );
  }
}
