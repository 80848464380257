import icons from "@newjersey/njwds/dist/img/sprite.svg";

interface Props {
  buttonType: "primary" | "outline";
  label: string;
  linkUrl: string;
  onClick: () => void;
  extraClassNames?: string;
  shouldOpenNewTab?: boolean;
}

function ExternalLinkButton({
  buttonType,
  label,
  linkUrl,
  onClick,
  extraClassNames,
  shouldOpenNewTab = true,
}: Props) {
  return (
    <a
      className={`usa-button usa-button--${buttonType} display-flex flex-align-center flex-justify-center ${extraClassNames}`}
      href={linkUrl}
      target={shouldOpenNewTab ? "_blank" : "_self"}
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {label}
      <svg
        className="usa-icon margin-left-1"
        aria-hidden="true"
        focusable="false"
        role="img"
      >
        <use xlinkHref={`${icons}#launch`}></use>
      </svg>
    </a>
  );
}

export default ExternalLinkButton;
