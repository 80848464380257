const TIMEOUT_MS = 10000;
export const fetchWithTimeout = async (url: string, timeout = TIMEOUT_MS) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(url, {
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
};
