import { useState, Dispatch, SetStateAction } from "react";
import Login from "./Login";
import Status from "./Status";
import { Page, SsoLoginStatus, StatusData, UserData } from "./shared/types";
import BackButton from "./BackButton";
import LogoutButton from "./LogoutButton";
import NoClaim from "NoClaim";
import { useTranslation } from "react-i18next";
import { logEvent } from "./shared/analytics";
import { FEATURE_FLAG_SHOW_I18N_BUTTON } from "shared/constants";

interface Props {
  page: Page;
  setPage: Dispatch<SetStateAction<Page>>;
}

function ClaimStatus({ page, setPage }: Props) {
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [ssoLoginStatus, setSsoLoginStatus] = useState(SsoLoginStatus.Pending);
  const [statusData, setStatusData] = useState<StatusData | undefined>(
    undefined
  );

  const { i18n } = useTranslation();
  const toggleLanguageButton = (
    <button
      className="margin-top-2 margin-right-2 unstyled-icon-button usa-link blue-override"
      onClick={toggleLanguage}
    >
      {i18n.language === "en" ? "Español" : "English"}
    </button>
  );

  function toggleLanguage() {
    const newLang = i18n.language === "en" ? "es" : "en";
    i18n.changeLanguage(newLang);

    const customEvent = new CustomEvent("changeLanguage", {
      detail: newLang,
      bubbles: true,
    });
    document.dispatchEvent(customEvent);

    logEvent("Switched language", newLang);
  }

  return (
    <>
      {page === Page.Login ? (
        <>
          {FEATURE_FLAG_SHOW_I18N_BUTTON && (
            <nav className="grid-container display-flex flex-justify-end">
              {toggleLanguageButton}
            </nav>
          )}
          <Login
            setPage={setPage}
            setUserData={setUserData}
            setStatusData={setStatusData}
            ssoLoginStatus={ssoLoginStatus}
            setSsoLoginStatus={setSsoLoginStatus}
          />
        </>
      ) : page === Page.Status ? (
        <>
          {ssoLoginStatus === SsoLoginStatus.Success ? (
            <nav className="grid-container display-flex flex-justify-end">
              {FEATURE_FLAG_SHOW_I18N_BUTTON && toggleLanguageButton}
              <LogoutButton />
            </nav>
          ) : (
            <>
              {FEATURE_FLAG_SHOW_I18N_BUTTON && (
                <nav className="grid-container display-flex flex-justify-end">
                  {toggleLanguageButton}
                </nav>
              )}
              <BackButton
                setPage={setPage}
                setUserData={setUserData}
                setStatusData={setStatusData}
              />
            </>
          )}
          <Status userData={userData} statusData={statusData} />
        </>
      ) : page === Page.NoClaim ? (
        <>
          <nav className="grid-container display-flex flex-justify-end">
            {FEATURE_FLAG_SHOW_I18N_BUTTON && toggleLanguageButton}
            <LogoutButton />
          </nav>
          <NoClaim userData={userData} statusData={statusData} />
        </>
      ) : null}
    </>
  );
}

export default ClaimStatus;
