import { LOGIN_URL } from "../shared/constants";
import { DataFetchStatus } from "../shared/types";
import { logEvent } from "../shared/analytics";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  fetchStatus: DataFetchStatus;
}

function SelfServiceError({ fetchStatus }: Props) {
  const { t } = useTranslation();

  let alertText;

  switch (fetchStatus) {
    case DataFetchStatus.ErrorSessionTimeout:
      alertText = (
        <p className="usa-alert__text">
          <Trans i18nKey="selfService.error.sessionTimeout">
            placeholder_child_0
            <a
              className="usa-link blue-override"
              href={LOGIN_URL}
              onClick={() => logEvent("Clicked expired session login link")}
            >
              placeholder_child_1
            </a>{" "}
            placeholder_child_2
          </Trans>
        </p>
      );
      break;
    case DataFetchStatus.ErrorIncorrectInfo:
      alertText = (
        <p className="usa-alert__text">
          {t("selfService.error.incorrectInfo")}
        </p>
      );
      break;
    default:
      alertText = <p className="usa-alert__text">{t("somethingWentWrong")}</p>;
      break;
  }

  return (
    <div className="usa-alert usa-alert--error usa-alert--slim">
      <div className="usa-alert__body">{alertText}</div>
    </div>
  );
}

export default SelfServiceError;
