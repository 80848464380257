import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { Page, StatusData, UserData } from "./shared/types";

interface Props {
  setPage: React.Dispatch<React.SetStateAction<Page>>;
  setUserData: React.Dispatch<React.SetStateAction<UserData | undefined>>;
  setStatusData: React.Dispatch<React.SetStateAction<StatusData | undefined>>;
}

function BackButton({ setPage, setUserData, setStatusData }: Props) {
  function onBack() {
    setUserData(undefined);
    setStatusData(undefined);
    setPage(Page.Login);
  }

  return (
    <div className="grid-container">
      <button
        className="usa-button usa-button--unstyled blue-override margin-top-2 display-flex flex-row flex-align-items-center"
        onClick={onBack}
      >
        <svg
          className="usa-icon margin-right-05"
          aria-hidden="true"
          focusable="false"
          role="img"
        >
          <use xlinkHref={icons + "#arrow_back"}></use>
        </svg>
        <span>Back</span>
      </button>
    </div>
  );
}

export default BackButton;
