import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { Decision } from "./types";
import { useTranslation } from "react-i18next";
import { FEATURE_FLAG_APPEALS_SORTING } from "../shared/constants";

interface DecisionTableHeaderProps {
  field: keyof Decision;
  onSort: (field: keyof Decision) => void;
  sortedAscending: boolean | undefined;
}

export default function DecisionTableHeader({
  field,
  onSort,
  sortedAscending,
}: DecisionTableHeaderProps) {
  const { t } = useTranslation();

  const getSortingInfo = (): { iconName: string; ariaSort: "ascending" | "descending" | undefined } => {
    if (sortedAscending) {
      return { iconName: "arrow_upward", ariaSort: "ascending" };
    } else if (sortedAscending === false) {
      return { iconName: "arrow_downward", ariaSort: "descending" };
    } else {
      return { iconName: "sort_arrow", ariaSort: undefined };
    }
  };

  const getText = (field: keyof Decision) => {
    switch (field) {
      case "decisionDate":
        return t("appeals.decisionDate");
      case "appealNumber":
        return t("appeals.appeal");
      case "issueCodes":
        return t("appeals.issue");
      case "dispositionCode":
        return t("appeals.decision");
      case "jurisdiction":
        return t("appeals.jurisdiction");
      case "appellant":
        return t("appeals.filedBy");
      default:
        throw new Error(`Unexpected field: ${field}`);
    }
  };

  const { iconName, ariaSort } = getSortingInfo();
  const text = getText(field);

  return (
    <th aria-sort={ariaSort} scope="col">
      {text}
      { FEATURE_FLAG_APPEALS_SORTING &&
        <button
          className="usa-table__header__button"
          onClick={() => onSort(field)}
          aria-label={`Sort by ${text}`}
        >
          <svg
            className="usa-icon"
            aria-hidden="true"
            focusable="false"
            role="img"
          >
            <use xlinkHref={`${icons}#${iconName}`} />
          </svg>
        </button>
      }
    </th>
  );
}
