import njLogo from "@newjersey/njwds/dist/img/nj-logo-gray-20.png";
import { useLocation } from "react-router";
import { Page } from "./shared/types";
import { useTranslation, Trans } from "react-i18next";
import { VERIFY_ROUTE, APPEALS_ROUTE } from "./shared/constants";

interface Props {
  page: Page;
}

function NJFooter({ page }: Props) {
  const { t } = useTranslation();
  const pathname = useLocation().pathname;

  const shouldDisplayForPathname = (pathname: string) => {
    switch (pathname) {
      case VERIFY_ROUTE:
        return true;
      case APPEALS_ROUTE:
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <footer className="usa-footer">
        <div className="grid-container usa-footer__return-to-top">
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" className="usa-link blue-override">
              {t("footer.returnToTop")}
            </a>
          }
        </div>
      </footer>
      {(page === Page.Status || shouldDisplayForPathname(pathname)) && (
        <aside data-testid="feedbackWidget">
          <feedback-widget
            contact-link="https://nj.gov/labor/myunemployment/help/contact-us/"
            only-save-rating-to-analytics="true"
          ></feedback-widget>
        </aside>
      )}
      <div className="usa-identifier">
        <section
          className="usa-identifier__section usa-identifier__section--masthead"
          aria-label="Agency identifier"
        >
          <div className="usa-identifier__container">
            <div className="usa-identifier__logos">
              <a href="https://nj.gov" className="usa-identifier__logo">
                <img
                  className="usa-identifier__logo-img"
                  src={njLogo}
                  alt={t("njLogo")}
                />
              </a>
            </div>
            <div
              className="usa-identifier__identity"
              aria-label="Agency description"
            >
              <p className="usa-identifier__identity-domain"></p>
              <p className="usa-identifier__identity-disclaimer">
                <Trans i18nKey="footer.officialSite">
                  placeholder_child_0
                  <a href="https://nj.gov">placeholder_child_1</a>
                </Trans>
              </p>
            </div>
          </div>
        </section>
        <nav
          className="usa-identifier__section usa-identifier__section--required-links"
          aria-label="Important links"
        >
          <div className="usa-identifier__container">
            <ul className="usa-identifier__required-links-list">
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/governor/admin/about/"
                  className="usa-identifier__required-link"
                >
                  {t("header.governor")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/governor/admin/lt/"
                  className="usa-identifier__required-link"
                >
                  {t("header.lieutenantGovernor")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.njHome")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/nj/gov/njgov/alphaserv.html"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.servicesAZ")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/nj/gov/deptserv/"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.departments")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/faqs/"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.faqs")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/nj/feedback.html"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.contactUs")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/nj/privacy.html"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.privacyNotice")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/nj/legal.html"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.legalStatement")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/nj/accessibility.html"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.accessibility")}
                </a>
              </li>
              <li className="usa-identifier__required-links-item">
                <a
                  href="https://nj.gov/opra/"
                  className="usa-identifier__required-link usa-link"
                >
                  {t("footer.opra")}
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <section
          className="usa-identifier__section usa-identifier__section--usagov"
          aria-label="U.S. government information and services"
        >
          <div className="usa-identifier__container">
            <div className="usa-identifier__usagov-description">
              {t("footer.copyright")} © {new Date().getFullYear()} State of New
              Jersey
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default NJFooter;
