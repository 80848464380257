import { SsoUserData, StatusData, UserData } from "shared/types";
import SsoVerificationDisplay from "verification/SsoVerificationDisplay";
import { useTranslation } from "react-i18next";

interface Props {
  userData: UserData | undefined;
  statusData: StatusData | undefined;
}

function SsoHeader({ userData, statusData }: Props) {
  const { t } = useTranslation();
  return (
    <>
      {userData != null && (
        <>
          <div className="margin-top-4">
            {t("status.headerGreeting")}{" "}
            <div className="font-body-lg">
              <strong>
                {(userData as SsoUserData).firstName}{" "}
                {(userData as SsoUserData).lastName}!
              </strong>
            </div>
            <div>
              <strong>{t("status.email")}: </strong>
              {(userData as SsoUserData).email}
            </div>
          </div>

          {statusData != null && statusData.status === "Success" && (
            <SsoVerificationDisplay
              verificationInformation={statusData.idVerification}
            />
          )}
        </>
      )}
    </>
  );
}

export default SsoHeader;
