import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { IdVerificationResponse, ResponseFetchStatus } from "shared/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { parseDate } from "shared/dateUtils";

interface Props {
  verificationInformation: IdVerificationResponse | undefined;
}

function SsoVerificationDisplay({ verificationInformation }: Props) {
  const { t, i18n } = useTranslation();
  const [isVerificationSummaryExpanded, setIsVerificationSummaryExpanded] =
    useState(false);

  if (verificationInformation == null) {
    return null;
  }
  const { fetchStatus, isVerified, verifiedDate } = verificationInformation;
  if (fetchStatus !== ResponseFetchStatus.Success) {
    return null;
  }

  const formattedVerificationStatus = !isVerified
    ? t("status.idVerification.incomplete")
    : verifiedDate != null
    ? `${t("status.idVerification.completedOn")} ${parseDate(
        verifiedDate,
        i18n.language
      )}`
    : t("status.idVerification.complete");

  return (
    <>
      <div className="display-inline-flex flex-row flex-align-center margin-top-0">
        <strong>{t("status.idVerification.title")}:</strong>&nbsp;
        {formattedVerificationStatus}
        <svg
          className={
            (isVerified ? "success " : "warning ") + "usa-icon margin-left-05"
          }
          aria-hidden="true"
          focusable="false"
          role="img"
        >
          <use
            xlinkHref={
              isVerified ? icons + "#check_circle" : icons + "#warning"
            }
          ></use>
        </svg>
        <button
          type="button"
          className="unstyled-icon-button display-inline-flex flex-row flex-align-center margin-left-05"
          aria-expanded={isVerificationSummaryExpanded}
          aria-label={
            (isVerificationSummaryExpanded
              ? t("collapse")
              : t("expand")) +
            t("status.idVerification.ariaLabelSummary")
          }
          tabIndex={0}
          onClick={() =>
            setIsVerificationSummaryExpanded(!isVerificationSummaryExpanded)
          }
          aria-controls="verification-summary"
        >
          {isVerificationSummaryExpanded ? (
            <svg
              className="usa-icon"
              aria-hidden="true"
              focusable="false"
              role="img"
            >
              <use xlinkHref={icons + "#expand_less"}></use>
            </svg>
          ) : (
            <svg
              className="usa-icon"
              aria-hidden="true"
              focusable="false"
              role="img"
            >
              <use xlinkHref={icons + "#expand_more"}></use>
            </svg>
          )}
        </button>
      </div>

      {isVerificationSummaryExpanded && (
        <div
          id="verification-summary"
          className="usa-summary-box"
          role="region"
          aria-labelledby="summary-box-key-information"
        >
          <div className="usa-summary-box__body">
            <h3
              className="usa-summary-box__heading"
              id="summary-box-key-information"
            >
              {t("status.idVerification.summary.heading")}
            </h3>
            <div className="usa-summary-box__text">
              {t("status.idVerification.summary.body")}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SsoVerificationDisplay;
