export function parseDate(date: string, locale: string) {
  let year, month, day;

  // check if it matches YYYY-MM-DD format
  const iso8601 = date.match(/\d{4}-\d{2}-\d{2}/g);
  if (iso8601) {
    [year, month, day] = iso8601[0].split("-");
  }

  // check if it matches MM/DD/YYYY format
  const monthDayFormat = date.match(/\d{2}\/\d{2}\/\d{4}/g);
  if (monthDayFormat) {
    [month, day, year] = monthDayFormat[0].split("/");
  }

  if (month && day && year) {
    const dateObj = new Date(
      parseInt(year),
      parseInt(month) - 1, // convert to month index
      parseInt(day)
    );
    const dateString = dateObj.toLocaleDateString(locale);
    return dateString;
  }

  // Use original value as fallback
  return date;
}

export function dateToDateAndTimeStrings(inputDate: Date, language: string, timeZone?: string) {
  const date = inputDate.toLocaleDateString(language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone
  });

  const time = inputDate.toLocaleTimeString(language, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone
  });

  return { date, time };
}
