import { FormEvent, useEffect, useRef, useState } from "react";
import { logEvent, logGA4Event } from "./shared/analytics";
import {
  DataFetchStatus,
  Page,
  SsoLoginStatus,
  StatusData,
  UserData,
} from "./shared/types";
import dolLogo from "./assets/img/dol_logo.png";
import { getStatusBasicAuth, getStatusSSO } from "./shared/functions/getStatus";
import { getCookie } from "./shared/functions/cookieUtils";
import {
  LOGIN_URL,
  CREATE_ACCOUNT_URL,
  SESSION_COOKIE_NAME,
} from "./shared/constants";
import { useTranslation, Trans } from "react-i18next";
import ExternalLinkButton from "ExternalLinkButton";

function formatSSN(ssn: string): string {
  // remove all non-dash and non-numerals
  let val = ssn.replace(/[^\d-]/g, "");

  // add the first dash if number from the second group appear
  val = val.replace(/^(\d{3})-?(\d{1,2})/, "$1-$2");

  // add the second dash if numbers from the third group appear
  val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, "$1-$2-$3");

  // remove misplaced dashes
  val = val
    .split("")
    .filter((val, idx) => {
      return val !== "-" || idx === 3 || idx === 6;
    })
    .join("");

  // enforce max length
  return val.substring(0, 11);
}

let ssoCheckHasRun = false;

interface Props {
  setPage: React.Dispatch<React.SetStateAction<Page>>;
  setUserData: React.Dispatch<React.SetStateAction<UserData | undefined>>;
  setStatusData: React.Dispatch<React.SetStateAction<StatusData | undefined>>;
  ssoLoginStatus: SsoLoginStatus;
  setSsoLoginStatus: React.Dispatch<React.SetStateAction<SsoLoginStatus>>;
}

function Login({
  setPage,
  setUserData,
  setStatusData,
  ssoLoginStatus,
  setSsoLoginStatus,
}: Props) {
  const [dataFetchStatus, setDataFetchStatus] = useState(DataFetchStatus.Idle);
  const [isBasicFormOpen, setIsBasicFormOpen] = useState(false);
  const userCanceledSso = useRef(false);

  /*
   * Look for a session cookie, check if ForgeRock is logged in and try to fetch the claim.
   * Utilize a boolean to ensure it runs only once in development environments.
   */
  useEffect(() => {
    if (!ssoCheckHasRun) {
      ssoCheckHasRun = true;
      const cookie = getCookie(SESSION_COOKIE_NAME);
      if (cookie == null) {
        setSsoLoginStatus(SsoLoginStatus.NoCookie);
        return;
      }

      getStatusSSO(cookie)
        .then(
          ({
            statusCode,
            status,
            claimStatus,
            claimType,
            dateOfClaim,
            selfServiceIds,
            taxYearCompensation,
            taxYearWithheld,
            email,
            firstName,
            lastName,
            idVerification,
            eAdjudication,
          }) => {
            // If user clicked to cancel the SSO-login, just leave the rest alone (giveUpOnSsoLogin should remain given-up)
            // NOTE: A ref is used because state can become stale (updates are not respected) inside a useEffect
            if (userCanceledSso.current) {
              return;
            }

            if (statusCode != null) {
              setSsoLoginStatus(SsoLoginStatus.Error);
              logEvent("Login error - ForgeRock", `Status code ${statusCode}`);
            } else if (status === "Success") {
              setSsoLoginStatus(SsoLoginStatus.Success);
              setDataFetchStatus(DataFetchStatus.Idle);
              setPage(Page.Status);
              setUserData({
                email: email ?? "No email found",
                firstName,
                lastName,
                isLoginSSO: true,
              });
              setStatusData({
                status,
                claimStatus,
                claimType,
                dateOfClaim,
                selfServiceIds,
                taxYearCompensation,
                taxYearWithheld,
                idVerification,
                eAdjudication,
              });

              // Needs to be 100 characters or less for GA4
              const logLabel = {
                status: claimStatus?.includes("Pending")
                  ? "Pending"
                  : claimStatus,
                date: dateOfClaim,
                ...(idVerification != null && {
                  idMe:
                    idVerification.fetchStatus === "Error"
                      ? "Error"
                      : idVerification.isVerified,
                }),
              };

              let caseStatuses = undefined;
              let caseIssues = undefined;
              let caseSources = undefined;
              if (eAdjudication != null) {
                if (eAdjudication.fetchStatus === "Error") {
                  caseStatuses = "Error";
                  caseIssues = "Error";
                  caseSources = "Error";
                } else if (
                  eAdjudication.fetchStatus === "Success" &&
                  eAdjudication.cases != null
                ) {
                  caseStatuses = eAdjudication.cases
                    .map((eAdjCase) => eAdjCase.Status)
                    .join(";");
                  caseIssues = eAdjudication.cases
                    .map((eAdjCase) => eAdjCase.IssueCode)
                    .join("|");
                  caseSources = eAdjudication.cases
                    .map((eAdjCase) => eAdjCase.Source)
                    .join(";");
                }
              }

              logGA4Event("Login success - ForgeRock", {
                event_category: "UI Claim Status",
                event_label: JSON.stringify(logLabel),
                ...(caseStatuses != null && {
                  object_status: caseStatuses,
                }),
                ...(caseIssues != null && {
                  object_type: caseIssues,
                }),
                ...(caseSources != null && {
                  object_details: caseSources,
                }),
              });
            } else if (status === "Error - Login failed") {
              setSsoLoginStatus(SsoLoginStatus.AccountHasNoClaim);
              setStatusData({
                status,
                claimStatus,
                claimType,
                dateOfClaim,
                selfServiceIds,
                taxYearCompensation,
                taxYearWithheld,
                idVerification,
              });
              setUserData({
                email: email ?? "No email found",
                firstName,
                lastName,
                isLoginSSO: true,
              });

              setPage(Page.NoClaim);
              logEvent("Login error - ForgeRock", "Incorrect info");
            } else {
              setSsoLoginStatus(SsoLoginStatus.Error);
              logEvent("Login error - ForgeRock", `API message: ${status}`);
            }
          }
        )
        .catch((error) => {
          setSsoLoginStatus(SsoLoginStatus.Error);
          logEvent(
            "Login error - ForgeRock",
            `Error message: ${error.message}`
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function giveUpOnSsoLogin() {
    setSsoLoginStatus(SsoLoginStatus.Error);
    userCanceledSso.current = true;
    setIsBasicFormOpen(true);
    logEvent("Login attempt - ForgeRock", "Canceled auth");
  }

  function openBasicForm() {
    setIsBasicFormOpen(true);
    logEvent("Clicked basic login option");
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setDataFetchStatus(DataFetchStatus.Loading);

    const formData = new FormData(e.target as HTMLFormElement);
    const loginData = Object.fromEntries(formData.entries());

    let dobMonth = loginData.dobMonth as string;
    let dobDay = loginData.dobDay as string;
    if (dobMonth.length === 1) {
      dobMonth = "0" + dobMonth;
    }
    if (dobDay.length === 1) {
      dobDay = "0" + dobDay;
    }
    const dobCleaned = `${dobMonth}/${dobDay}/${loginData.dobYear}`;

    const ssn = loginData.ssn as string;
    const ssnCleaned = ssn.split("-").join("");

    getStatusBasicAuth(ssnCleaned, dobCleaned)
      .then(
        ({
          status,
          claimStatus,
          claimType,
          dateOfClaim,
          selfServiceIds,
          taxYearCompensation,
          taxYearWithheld,
        }) => {
          switch (status) {
            case "Success": {
              setDataFetchStatus(DataFetchStatus.Idle);
              setPage(Page.Status);
              setUserData({
                socialSecurity: ssnCleaned,
                dateOfBirth: dobCleaned,
                isLoginSSO: false,
              });
              setStatusData({
                status,
                claimStatus,
                claimType,
                dateOfClaim,
                selfServiceIds,
                taxYearCompensation,
                taxYearWithheld,
              });
              logEvent("Login success", claimStatus);
              break;
            }
            case "Error - Login failed":
              setDataFetchStatus(DataFetchStatus.ErrorIncorrectInfo);
              logEvent("Login error", "Incorrect info");
              break;
            default:
              setDataFetchStatus(DataFetchStatus.ErrorUnknown);
              logEvent("Login error", "Unknown");
              break;
          }
        }
      )
      .catch((_error) => {
        setDataFetchStatus(DataFetchStatus.ErrorUnknown);
        logEvent("Login error", "Unknown");
      });

    return false;
  }

  const { t, i18n } = useTranslation();

  const MONTH_DROPDOWN_OPTIONS: Array<{
    value: string;
    displayText: string;
  }> = [
    { value: "", displayText: `- ${t("select")} -` },
    { value: "01", displayText: `01 - ${t("ssnLogin.months.jan")}` },
    { value: "02", displayText: `02 - ${t("ssnLogin.months.feb")}` },
    { value: "03", displayText: `03 - ${t("ssnLogin.months.mar")}` },
    { value: "04", displayText: `04 - ${t("ssnLogin.months.apr")}` },
    { value: "05", displayText: `05 - ${t("ssnLogin.months.may")}` },
    { value: "06", displayText: `06 - ${t("ssnLogin.months.jun")}` },
    { value: "07", displayText: `07 - ${t("ssnLogin.months.jul")}` },
    { value: "08", displayText: `08 - ${t("ssnLogin.months.aug")}` },
    { value: "09", displayText: `09 - ${t("ssnLogin.months.sep")}` },
    { value: "10", displayText: `10 - ${t("ssnLogin.months.oct")}` },
    { value: "11", displayText: `11 - ${t("ssnLogin.months.nov")}` },
    { value: "12", displayText: `12 - ${t("ssnLogin.months.dec")}` },
  ];

  const monthInput = (
    <div className="usa-form-group usa-form-group--month usa-form-group--select">
      <label className="usa-label" htmlFor="dobMonth">
        {t("ssnLogin.month")}
      </label>
      <select
        className="usa-select"
        id="dobMonth"
        name="dobMonth"
        data-testid="dobMonthDropdown"
        aria-describedby="dobHint"
      >
        {MONTH_DROPDOWN_OPTIONS.map((month) => (
          <option value={month.value} key={month.value}>
            {month.displayText}
          </option>
        ))}
      </select>
    </div>
  );

  const dayInput = (
    <div className="usa-form-group usa-form-group--day">
      <label className="usa-label" htmlFor="dobDay">
        {t("ssnLogin.day")}
      </label>
      <input
        className="usa-input"
        aria-describedby="dobHint"
        id="dobDay"
        name="dobDay"
        data-testid="dobDayInput"
        maxLength={2}
        pattern="[0-9]*"
        inputMode="numeric"
        required
      />
    </div>
  );

  const yearInput = (
    <div className="usa-form-group usa-form-group--year">
      <label className="usa-label" htmlFor="dobYear">
        {t("ssnLogin.year")}
      </label>
      <input
        className="usa-input"
        aria-describedby="dobHint"
        id="dobYear"
        name="dobYear"
        data-testid="dobYearInput"
        minLength={4}
        maxLength={4}
        pattern="[0-9]*"
        inputMode="numeric"
        required
      />
    </div>
  );
  return (
    <main className="main-section usa-prose margin-y-9">
      <img
        src={dolLogo}
        alt={t("dolLogo")}
        height={60}
        width={60}
      />
      <h1 className="title">
        {t("unemploymentInsurance")}
        <br />
        {t("claimStatus")}
      </h1>
      <section>
        {ssoLoginStatus === SsoLoginStatus.Pending && (
          <>
            <p className="padding-top-205">{t("loading.pleaseWait")}</p>
            <p>
              <Trans i18nKey="loading.ifThisTakesLonger">
                placeholder_child_0
                <button
                  className="usa-button usa-button--unstyled blue-override"
                  onClick={giveUpOnSsoLogin}
                  data-testid="giveUpOnSsoLink"
                >
                  placeholder_child_1
                </button>
                placeholder_child_2
              </Trans>
            </p>
            <div className="loader margin-top-4 margin-bottom-6" />
          </>
        )}

        {(ssoLoginStatus === SsoLoginStatus.NoCookie ||
          ssoLoginStatus === SsoLoginStatus.Error ||
          ssoLoginStatus === SsoLoginStatus.FeatureFlagDisabled) && (
          <>
            <div className="display-flex flex-column flex-align-stretch margin-top-5">
              <ExternalLinkButton
                buttonType="primary"
                label={t("emailLoginButton")}
                linkUrl={LOGIN_URL}
                onClick={() => logEvent("Clicked ForgeRock login link")}
                extraClassNames="login-button"
                shouldOpenNewTab={false}
              />
              <button
                className="usa-button--outline usa-button margin-top-1 login-button"
                disabled={isBasicFormOpen}
                onClick={openBasicForm}
                data-testid="ssnLoginButton"
              >
                {t("ssnLoginButton")}
              </button>
            </div>

            {!isBasicFormOpen && (
              <div className="margin-top-3">
                <p>
                  <Trans i18nKey="dontHaveAccount">
                    placeholder_child_0
                    <a
                      href={CREATE_ACCOUNT_URL}
                      className="usa-link usa-link--external blue-override"
                      onClick={() =>
                        logEvent("Clicked ForgeRock registration link")
                      }
                    >
                      placeholder_child_1
                    </a>
                  </Trans>
                </p>
              </div>
            )}

            {isBasicFormOpen && (
              <p>
                <strong>{t("ssnLogin.pleaseEnterFields")}</strong>
              </p>
            )}

            {dataFetchStatus === DataFetchStatus.ErrorUnknown && (
              <div className="usa-alert usa-alert--error usa-alert--slim">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">{t("somethingWentWrong")}</p>
                </div>
              </div>
            )}
            {dataFetchStatus === DataFetchStatus.ErrorIncorrectInfo && (
              <div className="usa-alert usa-alert--error usa-alert--slim">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">{t("ssnLogin.matchError")}</p>
                </div>
              </div>
            )}

            {isBasicFormOpen && (
              <form
                className="usa-form margin-top-0"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <fieldset className="usa-fieldset">
                  <label className="usa-label" htmlFor="ssn">
                    {t("ssnLogin.ssn")}
                  </label>
                  <span className="usa-hint" id="ssnHint">
                    {t("ssnLogin.forExample")} 123-45-6789
                  </span>
                  <input
                    className="usa-input"
                    id="ssn"
                    name="ssn"
                    data-testid="ssnInput"
                    pattern="^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$"
                    maxLength={11}
                    required
                    aria-describedby="ssnHint"
                    inputMode="numeric"
                    autoComplete="off"
                    onChange={(event) => {
                      event.target.value = formatSSN(event.target.value);
                    }}
                  />
                  <fieldset className="usa-fieldset margin-top-3">
                    <legend className="usa-legend">{t("ssnLogin.dob")}</legend>
                    <span className="usa-hint" id="dobHint">
                      {t("ssnLogin.forExample")} {t("ssnLogin.exampleDate")}
                    </span>
                    <div className="usa-memorable-date">
                      {i18n.language === "es" ? (
                        <>
                          {dayInput} {monthInput} {yearInput}
                        </>
                      ) : (
                        <>
                          {monthInput} {dayInput} {yearInput}
                        </>
                      )}
                    </div>
                  </fieldset>
                  <div className="margin-top-1">
                    <input
                      type="reset"
                      className="usa-button usa-button--outline"
                      value={t("ssnLogin.clear")}
                    />
                    <input
                      type="submit"
                      className="usa-button usa-button--primary"
                      value={t("ssnLogin.enter")}
                    />
                  </div>
                </fieldset>
              </form>
            )}

            {dataFetchStatus === DataFetchStatus.Loading && (
              <div className="loader margin-top-4" />
            )}
          </>
        )}
      </section>
      <section className="margin-top-3">
        <div className="usa-alert usa-alert--info margin-top-5 margin-bottom-9">
          <div className="usa-alert__body">
            <h2 className="usa-alert__heading">
              {t("healthResources.heading")}
            </h2>
            <p className="usa-alert__text margin-top-2">
              <Trans i18nKey="healthResources.ifYouNeedHealthInsurance">
                placeholder_child_0
                <a
                  href="http://www.njfamilycare.org/default.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="usa-link usa-link--external blue-override"
                >
                  placeholder_child_1
                </a>
                placeholder_child_2
              </Trans>
            </p>

            <p className="usa-alert__text margin-top-2">
              <Trans i18nKey="healthResources.forMoreInfoGetCoveredNJ">
                placeholder_child_0
                <a
                  href="https://www.nj.gov/getcoverednj/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="usa-link usa-link--external blue-override"
                >
                  placeholder_child_1
                </a>
                placeholder_child_2
              </Trans>
            </p>

            <p className="usa-alert__text margin-top-2">
              <Trans i18nKey={"healthResources.moreHealthResources"}>
                placeholder_child_0
                <a
                  href="https://www.nj.gov/labor/myunemployment/help/resources-support/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="usa-link usa-link--external blue-override"
                >
                  placeholder_child_1
                </a>
                placeholder_child_2
              </Trans>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Login;
