import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import dolLogo from "../assets/img/dol_logo.png";
import { Decision } from "./types";
import AppealsAlert from "./AppealsAlert";
import { ENDPOINTS } from 'shared/constants';
import { fetchWithTimeout } from 'shared/functions/fetchWithTimeout';
import { logGA4Event } from 'shared/analytics';
import { sanitizeAndRemoveStyle } from './utils';
import Disclaimer from './Disclaimer';

const APPEAL_ERROR = "Fetching appeals decision failed";

export default function AppealDetails() {
  const { numericalAppealNumber } = useParams();
  const [decision, setDecision] = useState<Decision | undefined>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { i18n, t } = useTranslation();

  const fetchDecision = useCallback(async () => {
    const fetchAddress = `${ENDPOINTS.appeals.basic}/${numericalAppealNumber}`;
    try {
      const response = await fetchWithTimeout(fetchAddress);
      if (response.status !== 200) {
        throw new Error(APPEAL_ERROR);
      }
      const { decision } = await response.json();
      if (decision == null) {
        throw new Error(APPEAL_ERROR);
      }
      setDecision(decision);
    } catch (error) {
      setError(true);
      const errorMessage = error instanceof Error ? error.message : String(error);
      logGA4Event("Appeal fetch error", { event_details: errorMessage });
    } finally {
      setLoading(false);
    }
  }, [numericalAppealNumber]);

  useEffect(() => {
    if (numericalAppealNumber == null || !Number.isInteger(Number(numericalAppealNumber))) {
      throw new Error(APPEAL_ERROR);
    }
    setError(false);
    setLoading(true);
    fetchDecision();
  }, [fetchDecision, numericalAppealNumber]);

  return (
    <>
      <Disclaimer />
      <main className="main-section margin-y-9">
        {error && <AppealsAlert message={
          <Trans
            i18nKey={"appeals.detailsError"}
            components={{ 1: <Link to=".." relative="path"/>}}
          />
        } />}
        <div className='details-row'>
          <img alt={t("dolLogo")} src={dolLogo} height={60} width={60} />
          <Link to=".." relative="path">
            {t("appeals.searchTitle")}
          </Link>
        </div>
        {
          !decision ? null :
            loading ? (
              <div className="table-loader-container">
                <div className="loader margin-top-4" data-testid="loader" />
              </div>
            ) : (
              <>
                <h1 className="text-bold">
                  {t("appeals.appealNumber", { numericalAppealNumber })}
                </h1>
                <div className="details-row">
                  <div className="details-column padding-right-1">
                    <div className='margin-bottom-1'>
                      <strong>
                        {t("appeals.decisionDate") + ": "}
                      </strong>
                        {new Date(decision.decisionDate).toLocaleDateString(i18n.language)}
                    </div>
                    <div className='margin-bottom-1'>
                      <strong>
                        {t("appeals.docketNumber") + ": "}
                      </strong>
                        {decision.docketNumber}
                    </div>
                    <div className='line-height-sans-2'>
                      <strong>
                        {t("appeals.issue") + ": "}
                      </strong>
                        {decision.issueCodes.join(", ")}
                    </div>
                  </div>
                  <div className="details-column">
                    <div className='margin-bottom-1'>
                      <strong>
                        {t("appeals.decision") + ": "}
                      </strong>
                      {
                        decision.dispositionCode
                          ? t("appeals.dispositions." + decision.dispositionCode.split(".")[0])
                          : ""
                      }
                    </div>
                    <div className='margin-bottom-1'>
                      <strong>
                        {t("appeals.jurisdiction") + ": "}
                      </strong>
                        {t("appeals.jurisdictions." + decision.jurisdiction)}
                    </div>
                    <div>
                      <strong>
                        {t("appeals.filedBy") + ": "}
                      </strong>
                        {t("appeals.appellants." + decision.appellant)}
                    </div>
                  </div>
                </div>
                <hr className="section-divider margin-bottom-3 bg-ink" />
                {
                  decision.decision && decision.decision.length > 0
                  ?
                  <div
                    className='usa-prose'
                    dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveStyle(decision.decision) }}
                  />
                  :
                  <p>{t("appeals.noDecisionText")}</p>
                }
              </>
            )
        }
      </main>
    </>
  );
}
