import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Appellant, FilterSet, Timeframe } from "./types";

export default function Filters({ onFilterSetSelected }: { onFilterSetSelected: (filterSet: FilterSet) => void }) {
  const { t } = useTranslation();
  const [appellant, setAppellant] = useState<Appellant>();
  const [issueCode] = useState("");
  const [timeframe, setTimeframe] = useState<Timeframe>();

  const onSearch = () => {
    onFilterSetSelected({ appellant, issueCode, timeframe });
  }

  return (
    <div className="margin-top-5">
      <span className="margin-top-1 margin-bottom-1">{t("appeals.showCasesAbout")} </span>
      <div className="usa-combo-box">
        {/* 
        TODO in https://github.com/newjersey/innovation-ui-mod-pm/issues/348
        <select
          className="usa-select margin-top-1 margin-bottom-1"
          aria-label={t('appeals.selectTopic')}
          id="issueCodes"
          name="issueCodes"
          value={issueCode || ''}
          onChange={e => setIssueCode(e.target.value)}
        >
          <option value="" disabled>{t('appeals.selectTopic')}</option>
        </select> */}
      </div>
      <span className="margin-top-1 margin-bottom-1"> {t("appeals.whereDecisionWas")} </span>
      <select
        className="usa-select margin-top-1 margin-bottom-1"
        aria-label={t("appeals.selectParty")}
        id="appellant"
        name="appellant"
        value={appellant || ''}
        onChange={e => setAppellant(e.target.value as Appellant)}
      >
        <option value="" disabled>{t("appeals.selectParty")}</option>
        {Object.values(Appellant).map(appellant => (
          <option key={appellant} value={appellant}>{t('appeals.appellants.' + appellant)}</option>
        ))}
      </select>
      <span className="margin-top-1 margin-bottom-1"> {t("appeals.withinTheLast")} </span>
      <select
        className="usa-select margin-top-1 margin-bottom-1"
        aria-label={t("appeals.selectTime")}
        id="timeframe"
        name="timeframe"
        value={timeframe || ''}
        onChange={e => setTimeframe(e.target.value as Timeframe)}
      >
        <option value="" disabled>{t("appeals.selectTime")}</option>
        {Object.values(Timeframe).map(timeframe => (
          <option key={timeframe} value={timeframe}>{t('appeals.timeframe.' + timeframe)}</option>
        ))}
      </select>
      <button
        className="usa-button margin-top-1 margin-bottom-1"
        type="button"
        onClick={onSearch}
      >
        {t("appeals.searchCases")}
      </button>
    </div>
  );
}
