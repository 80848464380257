import { EAdjCaseDynamoDB } from "shared/types";
import { logGA4Event } from "shared/analytics";
import ExternalLinkButton from "ExternalLinkButton";
import { deleteCookie } from "shared/functions/cookieUtils";
import { SESSION_COOKIE_NAME } from "shared/constants";
import { useTranslation } from "react-i18next";
import { parseDate } from "shared/dateUtils";

function truncateId(caseId: string) {
  return caseId.slice(-4);
}

function EAdjCaseAlert(props: { eAdjCase: EAdjCaseDynamoDB }) {
  const {
    Status,
    LinkExpirationDate,
    LinkSentDate,
    IssueCode,
    LinkUrl,
    CaseId,
    Source,
  } = props.eAdjCase;

  const { t, i18n } = useTranslation();

  function parseIssueCode(issueCodes: string) {
    const issueList = issueCodes.split(";");
    const descriptions = issueList
      .map((issue) =>
        t(`issueDescription.${issue.replaceAll(".", "_") ?? issue}`)
      )
      .join("; ");
    return descriptions;
  }

  if (Status === "Open") {
    return (
      <div className="usa-alert usa-alert--warning">
        <div className="usa-alert__body">
          <h3 className="usa-alert__heading">
            <span className="line-height-sans-2">
              {t("eAdjudication.submitAlert.heading", {
                date: parseDate(LinkExpirationDate, i18n.language),
              })}
            </span>
          </h3>
          <div className="usa-alert__text">
            <div className="margin-bottom-105">
              {t("eAdjudication.submitAlert.body")}
            </div>
            <div className="display-flex margin-bottom-05">
              <div className="margin-right-1">
                <strong>{t("eAdjudication.issue")}</strong>
              </div>
              <div>{parseIssueCode(IssueCode ?? "")}</div>
            </div>
            <div className="display-flex">
              <div className="margin-right-1">
                <strong>{t("eAdjudication.submitAlert.submitBy")}</strong>
              </div>
              <div>{parseDate(LinkExpirationDate, i18n.language)}</div>
            </div>
          </div>
        </div>
        <div className="padding-top-1 padding-bottom-2 padding-x-2 mobile-lg:margin-left-4 desktop:margin-left-6">
          <div className="display-flex flex-auto">
            <ExternalLinkButton
              buttonType="primary"
              label={t("eAdjudication.submitAlert.button")}
              linkUrl={LinkUrl}
              onClick={() => {
                deleteCookie(SESSION_COOKIE_NAME);
                logGA4Event(
                  "[UI Claim Status] Clicked button on eAdj Open case",
                  {
                    object_status: Source,
                    object_type: IssueCode,
                    object_details: JSON.stringify({
                      requested: LinkSentDate,
                      expired: LinkExpirationDate,
                    }),
                  }
                );
              }}
              extraClassNames="margin-bottom-105"
            />
          </div>
          <div className="font-sans-2xs margin-top-1 padding-x-105 mobile-lg:padding-x-0">
            {t("eAdjudication.submitAlert.give24hrs")}
          </div>
          <div className="padding-x-105 margin-top-1 mobile-lg:padding-x-0">
            <i>
              {t("eAdjudication.caseID")} {truncateId(CaseId)}
            </i>
          </div>
        </div>
      </div>
    );
  } else if (Status === "Open - Submitted") {
    return (
      <div className="usa-alert usa-alert--success">
        <div className="usa-alert__body">
          <h3 className="usa-alert__heading margin-bottom-2 line-height-sans-2">
            {t("eAdjudication.receivedAlert.heading")}
          </h3>
          <div className="usa-alert__text display-flex margin-bottom-1 padding-y-105">
            <div className="margin-right-1">
              <strong>{t("eAdjudication.issue")} </strong>
            </div>
            <div>{parseIssueCode(IssueCode ?? "")}</div>
          </div>
          <div className="usa-alert__text padding-bottom-105">
            {t("eAdjudication.receivedAlert.body")}
          </div>
          <div>
            <i>
              {t("eAdjudication.caseID")} {truncateId(CaseId)}
            </i>
          </div>
        </div>
      </div>
    );
  } else if (
    Status === "Closed - Unresolved" ||
    Status === "Open - No Response"
  ) {
    return (
      <div className="usa-alert usa-alert--warning">
        <div className="usa-alert__body">
          <h3 className="usa-alert__heading margin-bottom-2 line-height-sans-2">
            {t("eAdjudication.reopenAlert.heading")}
          </h3>
          <div className="display-flex margin-y-105">
            <div className="margin-right-1">
              <strong>{t("eAdjudication.issue")} </strong>
            </div>
            <div>{parseIssueCode(IssueCode ?? "")}</div>
          </div>
          <div className="usa-alert__text">
            {t("eAdjudication.reopenAlert.body", {
              date: parseDate(LinkExpirationDate, i18n.language),
            })}
          </div>
          <div className="margin-top-105">
            <i>
              {t("eAdjudication.caseID")} {truncateId(CaseId)}
            </i>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default EAdjCaseAlert;
