import { IdVerificationResponse, ResponseFetchStatus } from "shared/types";
import { VERIFY_ROUTE } from "../shared/constants";
import { logEvent } from "shared/analytics";
import ExternalLinkButton from "ExternalLinkButton";
import { useTranslation } from "react-i18next";

interface Props {
  verificationInformation: IdVerificationResponse | undefined;
  userEmail: string;
}

function SsoVerificationAlert({ verificationInformation, userEmail }: Props) {
  const { t } = useTranslation();

  if (verificationInformation == null) {
    return null;
  }
  const { fetchStatus, isVerified } = verificationInformation;

  return (
    <>
      {fetchStatus === ResponseFetchStatus.Error && (
        <div className="usa-alert usa-alert--error margin-top-2">
          <div className="usa-alert__body">
            <p className="usa-alert__text">
              {t("status.idVerification.errorAlert")}
            </p>
          </div>
        </div>
      )}
      {fetchStatus === ResponseFetchStatus.Success && isVerified === false && (
        <>
          <div className="usa-alert usa-alert--warning margin-top-2">
            <div className="display-flex flex-row flex-align-center flex-wrap">
              <div className="flex-2 margin-right-1 usa-alert__body">
                <h2 className="usa-alert__heading verification-alert-heading">
                  {t("status.idVerification.incompleteAlert.heading")}
                </h2>
                <p className="usa-alert__text font-sans-2xs">
                  {t("status.idVerification.incompleteAlert.body", {
                    optionalEmailString:
                      userEmail.length > 0 ? ` (${userEmail})` : "",
                  })}
                </p>
              </div>
              <ExternalLinkButton
                buttonType="primary"
                label="Verify Identity"
                linkUrl={VERIFY_ROUTE}
                onClick={() => logEvent("With SSO - clicked IDme button")}
                extraClassNames="margin-bottom-2 margin-x-3 margin-top-2 mobile-lg:margin-top-0 mobile-lg:margin-left-1 mobile-lg:margin-bottom-0"
              />
            </div>
          </div>
          <div className="text-italic text-base margin-top-05 font-sans-2xs">
            {t("status.idVerification.ifAlreadyCompleted")}
            <br />
            {t("status.idVerification.ifAlreadyCertifying")}
            <br />
            {t("status.idVerification.ifVerified")}
          </div>
        </>
      )}
    </>
  );
}

export default SsoVerificationAlert;
