import { SESSION_COOKIE_NAME } from "../constants";

export const getCookie = (cookieName: string) => {
  const cookies = document.cookie;
  const cookieArrayParts = cookies
    .split("; ")
    .map((keyAndValue) => keyAndValue.split("="))
    .filter(
      (cookieParts) =>
        cookieParts && cookieParts.length > 0 && cookieParts[0] === cookieName
    )[0];
  return cookieArrayParts != null ? cookieArrayParts[1] : null;
};

export const deleteCookie = (cookieName: string) => {
  let cookie = getCookie(cookieName);

  if (cookie == null || cookie === "") {
    return;
  }

  document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const shouldKeepCookieForBrowser = (cookieName: string) => {
  return  /^.*incap.*$/.test(cookieName) ||
    /^.*nlbi.*$/.test(cookieName) ||
    cookieName === SESSION_COOKIE_NAME;
}

export const getReducedBrowserCookies = () => {
  const cookies = document.cookie;
  return cookies
    .split("; ")
    .map((keyAndValue) => keyAndValue.split("="))
    .filter(
      (cookieParts) =>
        cookieParts && cookieParts.length > 0 && shouldKeepCookieForBrowser(cookieParts[0])
    )
    .map((cookieParts) => cookieParts.join("="))
    .join("; ");
};
