import { logEvent } from "shared/analytics";
import { FILE_CLAIM_URL } from "shared/constants";
import dolLogo from "./assets/img/dol_logo.png";
import { StatusData, UserData } from "shared/types";
import SsoHeader from "SsoHeader";
import { useTranslation } from "react-i18next";

interface Props {
  userData: UserData | undefined;
  statusData: StatusData | undefined;
}

function NoClaim({ userData, statusData }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <main className="main-section usa-prose margin-y-9">
        <img
          src={dolLogo}
          alt={t("dolLogo")}
          height={60}
          width={60}
        />

        <SsoHeader userData={userData} statusData={statusData} />

        <h1 className="title">{t("status.title")}</h1>

        <div className="usa-alert usa-alert--error margin-bottom-3">
          <div className="usa-alert__body">
            <h2 className="usa-alert__heading">{t("noClaim.heading")}</h2>
            <p className="usa-alert__text">{t("noClaim.body")}</p>
          </div>
        </div>

        <div>{t("noClaim.fileClaimPrompt")}</div>
        <div>
          <a
            href={FILE_CLAIM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="usa-link usa-link--external blue-override"
            onClick={() =>
              logEvent("Clicked application link on No Claim page")
            }
          >
            {t("noClaim.fileClaimLink")}
          </a>
        </div>
      </main>
    </>
  );
}

export default NoClaim;
