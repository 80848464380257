import { useTranslation } from "react-i18next";

export default function AppealsAlert({ message }: { message: string | React.ReactNode }) {
  const { t } = useTranslation();

  return(
    <div className="usa-alert usa-alert--error margin-y-3" role="alert">
      <div className="usa-alert__body">
        <h1 className="usa-alert__heading">{t("error")}</h1>
        <p className="usa-alert__text">
          {message}
        </p>
      </div>
    </div>
  );
}
