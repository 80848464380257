import stateSeal from "@newjersey/njwds/dist/img/nj_state_seal.png";
import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();

  return (
    <section className="nj-banner" aria-label={t("header.officialLabel")}>
      <header className="nj-banner__header">
        <div className="grid-container">
          <div className="nj-banner__inner">
            <div className="grid-col-auto">
              <img
                className="nj-banner__header-seal"
                src={stateSeal}
                alt={t("header.njFlagAlt")}
              />
            </div>
            <div className="grid-col-fill">
              <a href="https://nj.gov">{t("header.officialSite")}</a>
            </div>
            <div className="grid-col-auto">
              <div className="text-white">
                <ul>
                  <li>
                    {t("header.governor")} &bull;{" "}
                    {t("header.lieutenantGovernor")}
                  </li>
                  <li>
                    <a
                      href="https://nj.gov/subscribe/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        className="usa-icon bottom-neg-2px margin-right-05"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                      >
                        <use xlinkHref={icons + "#mail"}></use>
                      </svg>
                      {t("header.getUpdates")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
}

export default Header;
