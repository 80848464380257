import { useState, useEffect, useRef } from "react";
import dolLogo from "../assets/img/dol_logo.png";

import {
  ENDPOINTS,
  IDME_URL,
  IDME_CLIENT_ID,
  IDME_REDIRECT_URI,
  IDME_RESPONSE_TYPE,
  IDME_SCOPE,
  IDME_OPEN_PAGE,
  IDME_AUTHENTICATION_ERROR,
  IDME_STORE_ERROR,
  VERIFY_ROUTE,
} from "../shared/constants";
import { FAQ_ACCORDION_ENTRIES } from "./IdMeFAQContent";
import Accordion from "../Accordion";
import { logEvent } from "../shared/analytics";

const GET_IDME_RESP_ENDPOINT = ENDPOINTS.getIdmeResponse.basic;
const IDME_LOCAL_STORAGE_KEY_NAME = "idVerifiedDate";
const IDME_LOCAL_STORAGE_TIMEOUT = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const IDME_COMPLETE_URL = `${IDME_URL}?client_id=${IDME_CLIENT_ID}&redirect_uri=${IDME_REDIRECT_URI}&response_type=${IDME_RESPONSE_TYPE}&scope=${IDME_SCOPE}&op=${IDME_OPEN_PAGE}`;

function IdMeLogin() {
  const [isVerified, setIsVerified] = useState<Boolean>();
  const [previouslyVerifiedDate, setPreviouslyVerifiedDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const hasEffectRan = useRef(false);

  useEffect(() => {
    if (!hasEffectRan.current) {
      hasEffectRan.current = true;

      const idMePreviouslyVerifiedDate =
        localStorage.getItem(IDME_LOCAL_STORAGE_KEY_NAME) ?? "";

      if (idMePreviouslyVerifiedDate !== "") {
        const verifiedDate = new Date(idMePreviouslyVerifiedDate);
        const currentDate = new Date().getTime();

        if (
          currentDate - verifiedDate.getTime() <=
          IDME_LOCAL_STORAGE_TIMEOUT
        ) {
          setPreviouslyVerifiedDate(verifiedDate);
        } else {
          localStorage.removeItem(IDME_LOCAL_STORAGE_KEY_NAME);
        }
      }

      if (window.location.href.includes("code")) {
        const authCodeParamString =
          window.location.href.split("code=")[1] ?? "";
        const authCode = authCodeParamString.split("&")[0];
        const fetchAddr = `${GET_IDME_RESP_ENDPOINT}?code=${authCode}`;

        if (authCode != null) {
          setIsLoading(true);
          fetch(fetchAddr, {
            method: "GET",
          })
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(IDME_AUTHENTICATION_ERROR);
              }
              return response.json();
            })
            .then(({ status }) => {
              if (status === "Success") {
                setIsVerified(true);
                localStorage.setItem(
                  IDME_LOCAL_STORAGE_KEY_NAME,
                  new Date().toString()
                );
                window.history.replaceState({}, "", VERIFY_ROUTE);
                logEvent("ID.me verification successfully checked");
              } else {
                throw new Error(IDME_STORE_ERROR);
              }
            })
            .catch((error) => {
              if (error.message === IDME_STORE_ERROR) {
                logEvent("ID.me verification error", "ID.me store API error");
              } else if (error.message === IDME_AUTHENTICATION_ERROR) {
                logEvent(
                  "ID.me verification error",
                  "ID.me user authentication failed"
                );
              } else {
                logEvent("ID.me verification error", "Unknown");
              }
              window.history.replaceState({}, "", VERIFY_ROUTE);
              setIsVerified(false);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    }
  }, []);

  return (
    <main className="main-section usa-prose margin-y-9">
      <img
        src={dolLogo}
        alt="New Jersey Department of Labor and Workforce Development official logo"
        height={60}
        width={60}
      />
      {!(isVerified === undefined || isVerified === null) && !isVerified && (
        <>
          <div
            className="usa-alert usa-alert--error"
            role="alert"
            data-testid="idMeErrorText"
          >
            <div className="usa-alert__body">
              <p className="usa-alert__text info-alert-text">
                We were unable to verify your identity. Please try again.
              </p>
            </div>
          </div>
        </>
      )}
      {isVerified && (
        <>
          <div className="usa-alert usa-alert--success" role="alert">
            <div className="usa-alert__body">
              <p
                className="usa-alert__text info-alert-text"
                data-testid="idMeSuccessText"
              >
                Your identity has been verified.
              </p>
            </div>
          </div>
        </>
      )}
      {previouslyVerifiedDate !== undefined && (
        <>
          <div className="usa-alert usa-alert--success" role="alert">
            <div className="usa-alert__body">
              <p>
                Our records show you verified on this device on{" "}
                {previouslyVerifiedDate
                  .toLocaleDateString(undefined, {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .replace(",", " at")}
                . If this wasn't you, start over{" "}
                <a
                  href={VERIFY_ROUTE}
                  onClick={() =>
                    localStorage.removeItem(IDME_LOCAL_STORAGE_KEY_NAME)
                  }
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
        </>
      )}
      <h1 className="title">
        Verify your identity
        <br />
        with ID.me
      </h1>
      <div className="margin-top-3">
        <p className="font-sans-xs">
          This site is for New Jersey claimants to complete the ID.me
          verification process. Identity verification is required for your claim
          to be reviewed for eligiblity.
        </p>
      </div>

      {(isVerified == null || isVerified === undefined || !isVerified) &&
        previouslyVerifiedDate === undefined &&
        isLoading === false && (
          <>
            <div className="grid-container margin-top-1 padding-0">
              <div className="grid-row">
                <div className="usa-alert usa-alert--warning">
                  <div className="usa-alert__body">
                    <p
                      className="usa-alert__text info-alert-text"
                      data-testid="idMeEmailWarning"
                    >
                      Use the same email address you used to file your claim.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      <div className="margin-top-1 display-none mobile-lg:display-flex"> </div>
      <div className="display-flex flex-column flex-align-stretch margin-top-3">
        {/* eslint-disable-next-line */}
        <a
          href={
            previouslyVerifiedDate !== undefined ||
            isLoading === true ||
            isVerified === true
              ? "#"
              : IDME_COMPLETE_URL
          }
          target="_parent"
          className={`usa-button usa-button--primary login-button verify-button usa-link--external display-flex flex-justify-center padding-2 ${
            isLoading === true ||
            isVerified === true ||
            previouslyVerifiedDate !== undefined
              ? "disabled"
              : ""
          }`}
          data-testid="verifyIdMeBtn"
          onClick={() => logEvent("Clicked ID.me verification link")}
        >
          Verify identity
        </a>
      </div>

      <div className="margin-top-3">
        <p className="text-base">
          By verifying with ID.me, you consent to the sharing of your personal
          information collected during the process.
        </p>
      </div>

      {isLoading && (
        <div className="margin-top-2 margin-bottom-5 display-flex flex-align-center">
          <div className="loader"></div>
          <div className="margin-left-2 text-color-blue">
            Verifying your identity, please do not refresh the page...
          </div>
        </div>
      )}

      <hr className="section-divider margin-y-4 border-base-light" />
      <h2 className="section-title">FAQs</h2>

      <Accordion
        items={FAQ_ACCORDION_ENTRIES}
        analyticsAction="Opened accordion entry in ID.me FAQ"
        extraButtonClassNames="margin-bottom-3"
      />
    </main>
  );
}
export default IdMeLogin;
