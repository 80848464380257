import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@newjersey/njwds/dist/css/styles.css";
import "@newjersey/njwds/dist/js/uswds-init.min.js";
import "@newjersey/njwds/dist/js/uswds.min.js";
import "./i18n/config.ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
