import FAQStopandRead from "../assets/img/idme_faq/idme_faq_stop_and_read.png";
import FAQConfirmation from "../assets/img/idme_faq/idme_faq_completion.png";
import FAQConfirmationEmail from "../assets/img/idme_faq/idme_faq_confirmation_email.png";
import { logEvent } from "../shared/analytics";

export const FAQ_ACCORDION_ENTRIES: Array<{
  title: string;
  content: JSX.Element;
  id: string;
}> = [
  {
    title: "Why do I have to verify my identity?",
    content: (
      <>
        <p>
          The New Jersey Division of Unemployment Insurance has partnered with
          ID.me to verify the identity of all workers applying for Unemployment
          Insurance (UI) benefits. This required step helps protect your
          identity and your benefits against attempted fraud. Before you can
          receive unemployment benefits you must verify your identity through
          ID.me.
        </p>
        <p>
          If you think your identity has been compromised and you want to let us
          know,{" "}
          <a
            href="https://nj.gov/labor/myunemployment/help/contact-us/reportfraud/"
            onClick={() =>
              logEvent(
                "Clicked link in ID.me FAQ",
                "NJDOL report identity compromised"
              )
            }
          >
            click here to report potential fraud
          </a>
          .
        </p>
      </>
    ),
    id: "why-verify",
  },
  {
    title: "How long does the identity verification process take?",
    content: (
      <>
        <p>
          The process can be completed in a few minutes using the email address
          associated with your initial unemployment claim, a computer or mobile
          phone, a copy of your social security number, and common identity
          verification documents. Once you completed your verification, it can
          take NJ Unemployment up to 15 business days to process. If you don't
          hear back after 15 business days, please reach out for support.
        </p>
      </>
    ),
    id: "how-long",
  },
  {
    title: "How do I get started?",
    content: (
      <>
        <p>
          We offer three options for you to verify your identity. Choose the
          method that's convenient for you.
        </p>
        <p>Important things to remember:</p>
        <ol>
          <li>
            Sign up for ID.me with the same email address you used on your
            initial unemployment claim application if possible to avoid delays
            in processing your application.
          </li>
          <li>
            To verify your identity, you'll need your social security number and
            one or more identity verification documents, depending on which
            method of verification you choose.
            <b>
              You must show the documents listed below for your verification
              method in order to successfully complete identity verification.
            </b>
            <ol type="a">
              <li>
                One of the following documents: driver's license, state ID,
                passport card, passport. Make sure you have this ready when you
                begin the process.
                <ol type="i">
                  <li>
                    One of the following documents: driver's license, state ID,
                    passport card, passport. Make sure you have this ready when
                    you begin the process.
                  </li>
                  <li>
                    In some cases, you will be asked for further documentation.
                    If this is the case, you will need either two primary
                    documents or one primary and two secondary documents from{" "}
                    <a
                      href="https://help.id.me/hc/en-us/articles/360017833054-Primary-and-secondary-identification-documents"
                      onClick={() =>
                        logEvent(
                          "Clicked link in ID.me FAQ",
                          "ID.me identity documents article"
                        )
                      }
                    >
                      this list
                    </a>
                    . We recommend that you prepare those documents just in case
                    you may need them.
                  </li>
                </ol>
              </li>
              <li>
                If you verify your identity at a UPS location, you will need:
                <ol type="i">
                  <li>
                    One identification document from the “Primary Documents”
                    list{" "}
                    <a
                      href="https://help.id.me/hc/en-us/articles/4410262325143"
                      onClick={() =>
                        logEvent(
                          "Clicked link in ID.me FAQ",
                          "ID.me in-person documents article"
                        )
                      }
                    >
                      here
                    </a>
                    . <b>Make sure you bring this with you. </b>
                  </li>
                  <li>
                    One identification document with your current valid U.S.
                    address. <b>Make sure you bring this with you. </b>
                  </li>
                  <li>
                    In some cases, you may be asked to show a document with your
                    full social security number. If you have a document with
                    your social security number on hand, we recommend bringing
                    it along.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p>Choose how to verify your identity:</p>
        <ul>
          <li>
            <b>Self service:</b> Select the Self Service method, and you will be
            guided through this online process. This is the fastest way to
            complete your identity verification. You can use your own mobile
            phone or computer that has a camera, or walk in to a One-Stop Career
            Center, which will have a computer you can use to complete this
            process.
          </li>
          <li>
            <b>Video chat:</b> Select the Video Chat Agent method and meet
            online with an ID.me employee to walk you through the process. You
            can use your own mobile phone or computer that has a camera, or walk
            in to a One-Stop Career Center, which will have a computer you can
            use to complete this process.
          </li>
          <li>
            <b>In person support:</b> You can meet in person with a support
            specialist who can help you complete your identity verification
            application. There are two ways to do this:
            <ul>
              <li>
                <a
                  href="https://telegov.njportal.com/njdolui/AppointmentWizard/"
                  onClick={() =>
                    logEvent(
                      "Clicked link in ID.me FAQ",
                      "NJDOL appointment wizard"
                    )
                  }
                >
                  Schedule an appointment
                </a>
                with a NJDOL UI staff who will help you with the identity
                verification process at a One-Stop Career Center. You can find
                all the One-Stop locations{" "}
                <a
                  href="https://www.nj.gov/labor/career-services/contact-us/one-stops/"
                  onClick={() =>
                    logEvent(
                      "Clicked link in ID.me FAQ",
                      "NJDOL one-stop career center webpage"
                    )
                  }
                >
                  here
                </a>
                . We recommend making an appointment, but you may walk in to a
                One-Stop Career Center. Please note that if you do walk in, you
                may need to wait and may not be able to be served. Please make
                sure you have a record of your Social Security number and two
                additional identity documents when you come in for your
                appointment.
              </li>
              <li>
                Or select the{" "}
                <a
                  href="https://help.id.me/hc/en-us/articles/1500005127662"
                  onClick={() =>
                    logEvent(
                      "Clicked link in ID.me FAQ",
                      "ID.me verifying for NJDOL article - Getting started"
                    )
                  }
                >
                  In Person Verification
                </a>{" "}
                method, where you will be prompted to enter your zip code and
                city name to locate the most convenient location for your
                appointment. There are 29 UPS locations around the state that
                partner with ID.me to offer in person support. We recommend
                making an appointment, but you can choose to walk-in any time
                during regular business hours. You must submit your unemployment
                insurance application and create an ID.me account online before
                you come in. If you scheduled an appointment, you will receive a
                confirmation email with a QR code that you will show when you
                come in and a list of documents you will need to bring to your
                appointment in order to complete your application.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          If you have questions about this required identity verification, visit{" "}
          <a
            href="http://help.id.me/"
            onClick={() =>
              logEvent("Clicked link in ID.me FAQ", "ID.me help page")
            }
          >
            help.id.me
          </a>
          .
        </p>
      </>
    ),
    id: "how-to-start",
  },
  {
    title:
      "I don't have access to a computer or phone. How else can I verify my identity?",
    content: (
      <>
        <p>
          Use one of these alternate resources if you are unable to access your
          email on a computer or phone:
        </p>
        <ul>
          <li>One-Stop Career Center public computer</li>
          <li>
            Your town's public library computer (please note that the computer
            will need a camera)
          </li>
        </ul>
      </>
    ),
    id: "device-access",
  },
  {
    title: "How do I verify my identity if I am a minor?",
    content: (
      <>
        <p>
          The identity of workers under the age of 18 is verified by NJDOL
          staff.{" "}
          <a
            href="mailto:idproofing@dol.nj.gov"
            onClick={() =>
              logEvent(
                "Clicked link in ID.me FAQ",
                "ID proofing help email address"
              )
            }
          >
            Email: idproofing@dol.nj.gov
          </a>
        </p>
      </>
    ),
    id: "verify-as-minor",
  },
  {
    title:
      "Can I select a language other than English to complete the verification process?",
    content: (
      <>
        <p>
          Yes. After clicking the link to verify your identity, select the
          language you prefer to complete the ID.me verification process.
        </p>
        <p>
          You also have the option of completing the process via a live video
          conference session in the language of your choice.
        </p>
      </>
    ),
    id: "select-language",
  },
  {
    title: "I'm having issues creating an account. Who can help me?",
    content: (
      <>
        <p>
          Submit a request for help here:{" "}
          <a
            href="https://help.id.me/hc/en‐us/p/contact_support"
            onClick={() =>
              logEvent(
                "Clicked link in ID.me FAQ",
                "ID.me support page - Account creation issues"
              )
            }
          >
            https://help.id.me/hc/en‐us/p/contact_support
          </a>
          . NJDOL staff are unable to access your ID.me account or assist with
          the ID.me process.
        </p>
      </>
    ),
    id: "account-creation-issues",
  },
  {
    title:
      "When I click New Jersey's link to verify my identity, I see a screen that says 'STOP AND READ' (pictured below). What should I do?",
    content: (
      <>
        <img
          src={FAQStopandRead}
          alt="Screenshot of Stop and Read alert"
          width={300}
        />
        <p>
          You must select "Yes" so New Jersey can receive and review your
          information without delay.
        </p>
      </>
    ),
    id: "stop-and-read",
  },
  {
    title:
      "I already verified my identity for the IRS. Do I have to do it again for NJ unemployment?",
    content: (
      <>
        <p>
          If you have already completed ID.me verification for another agency
          (such as the IRS, SSA, or Veterans Affairs), please log in to your
          ID.me account. You will be instructed on how to share that proof of
          verification with NJ and if there is anything additional you may be
          required to do.
        </p>
      </>
    ),
    id: "already-verified-irs",
  },
  {
    title:
      "I completed the identity verification process through ID.me. What happens now?",
    content: (
      <>
        <p>
          When you successfully complete the ID.me verification process, ID.me
          will email this message (pictured here): "We confirmed your identity!"
        </p>
        <img
          src={FAQConfirmation}
          alt="Screenshot of confirmation email from ID.me"
          width={300}
        />

        <p>
          However, this confirmation email from ID.me is not enough to begin
          certifying. You must <b>wait</b> for a New Jersey Unemployment
          confirmation email from ui-noreply@dol.nj.gov (pictured here):
        </p>
        <img
          src={FAQConfirmationEmail}
          alt="Screenshot of confirmation email sent from NJ
              DOL"
          width={300}
        />
        <p>
          This email will direct you to start certifying and provide you with
          next steps for your claim.
        </p>
        <p>
          Please note: Even if you successfully verify your identity, your
          unemployment claim may be denied for other eligibility reasons.
        </p>
      </>
    ),
    id: "what-happens-now",
  },
  {
    title:
      "I received both confirmation emails that my identity was verified and I've begun weekly certifying, but I still have not received payment from my unemployment benefit. What should I do?",
    content: (
      <>
        <p>
          Please make sure your weekly certifications have been successfully
          submitted for each week you have been unemployed. If there are
          eligibility issues with your unemployment claim, NJDOL will need
          additional time to resolve these issues. You may receive requests for
          further information from New Jersey in order to process your claim.
          Please check your email inbox for requests for information from
          ui-noreply@dol.nj.gov
        </p>
      </>
    ),
    id: "emails-no-payment",
  },
  {
    title:
      "What if I did not receive a confirmation that I verified my identity successfully?",
    content: (
      <>
        <p>
          If you have not received the confirmation email, you likely did not
          complete the ID.me process. Please try verifying your identity again.
          Please reach out to ID.me support{" "}
          <a
            href="https://help.id.me/hc/en-us/p/contact_support"
            onClick={() =>
              logEvent(
                "Clicked link in ID.me FAQ",
                "ID.me support page - No confirmation received"
              )
            }
          >
            https://help.id.me/hc/en-us/p/contact_support
          </a>{" "}
          if you believe you completed your verification and need support.
        </p>
      </>
    ),
    id: "confirmation-not-received",
  },
  {
    title:
      "I might have used a different email address to verify my identity than the email address I used to file for my unemployment benefit. What do I do?",
    content: (
      <>
        <p>
          If the email address on your ID.me account does not match the email
          address on your ID.me account, your claim may take up to 7 additional
          business days to process.
        </p>
      </>
    ),
    id: "used-different-email",
  },
  {
    title: "Will I ever need to repeat the identity verification process?",
    content: (
      <>
        <p>
          Each UI claim is valid for one year and you will not need to repeat
          the ID verification during that year. When opening a new claim, you
          will be asked to sign in to your existing ID.me account to link your
          existing verification to the new claim but will not have to repeat the
          verification steps.
        </p>
      </>
    ),
    id: "repeat-process",
  },
  {
    title:
      "I do not want to share my biometrics data, how else can I verify my identity?",
    content: (
      <>
        <p>
          You can use the video chat option to verify your identity if you do
          not want to share biometric data.
        </p>
        <p>
          Select the{" "}
          <a
            href="https://help.id.me/hc/en-us/articles/1500005127662"
            onClick={() =>
              logEvent(
                "Clicked link in ID.me FAQ",
                "ID.me verifying for NJDOL article - Biometrics data"
              )
            }
          >
            In Person Verification
          </a>{" "}
          method, where you will be prompted to enter your zip code and city
          name to locate the most convenient location for your appointment.
          There are 29 UPS locations around the state that partner with ID.me to
          offer in person support. We recommend making an appointment, but you
          can choose to walk-in any time during regular business hours. You must
          submit your unemployment insurance application and create an ID.me
          account online before you come in. If you scheduled an appointment,
          you will receive a confirmation email with a QR code that you will
          show when you come in and a list of documents you will need to bring
          to your appointment in order to complete your application.
        </p>
      </>
    ),
    id: "biometrics-data",
  },
  {
    title:
      "What if I choose to not participate in identity proofing, or fail to prove my identity when I first apply?",
    content: (
      <>
        <p>
          If you choose not to participate in the identity verification process,
          or you fail the verification process, you can still submit your
          application. However, we will not make any benefit payments to you
          until we confirm that your identity has been verified.
        </p>
      </>
    ),
    id: "participation",
  },
];
