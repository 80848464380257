import DOMPurify from 'dompurify';
import { Decision } from "./types";

export const getNumericalAppealNumber = (decision: Decision): string => {
  return decision.appealNumber.split(" ")[1];
};

export const sanitizeAndRemoveStyle = (htmlString: string): string => {
  const doc = new DOMParser().parseFromString(DOMPurify.sanitize(htmlString), 'text/html');
  Array.from(doc.body.querySelectorAll('[style]')).forEach(el => el.removeAttribute('style'));
  return new XMLSerializer().serializeToString(doc.body);
}
