import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { LOGOUT_URL } from "./shared/constants";
import { useTranslation } from "react-i18next";

function LogoutButton() {
  const { t } = useTranslation();
  return (
    <div className="flex-align-self-end">
      <a
        className="usa-button usa-button--outline margin-top-2 display-flex flex-row flex-align-items-center"
        href={LOGOUT_URL}
      >
        <svg
          className="usa-icon margin-right-05"
          aria-hidden="true"
          focusable="false"
          role="img"
        >
          <use xlinkHref={`${icons}#logout`}></use>
        </svg>
        <span>{t("logout")}</span>
      </a>
    </div>
  );
}

export default LogoutButton;
